import React from "react";
import Card from "../components/Card/Card";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import Nouknow from "../assets/nouknow.png";
import { useCheckErrorThrowError } from "../utils/checkError";
import useUsc021Actions from "../hooks/pages/USC021/useUsc021DBActions";

const TopNouknowCardWithdrawal = () => {
  const navigate = useNavigateWithUrl();
  const { error, listResult } = useUsc021Actions({
    orderYear: null,
  });

  // エラー処理
  useCheckErrorThrowError([error]);

  const handleChangeExamination = () => {
    const queryParams = new URLSearchParams();
    queryParams.set("category", "1");

    navigate(`${PATIENT.USC026}?${queryParams.toString()}`);
  };

  return (
    <Card
      header={
        <LayoutBox
          gap="1/2x"
          justify="start"
          align="start"
          flexShrink={0}
          direction="row"
          minHeight="65px"
        >
          <img
            src={Nouknow}
            alt="nouknow"
            className={`image--icon image--nouknow ${
              !listResult || listResult.length === 0
                ? "image--icon--disabled"
                : ""
            }`}
          />
          <LayoutBox
            gap="1/2x"
            justify="center"
            align="start"
            flexShrink={0}
            direction="column"
          >
            <Text bold size="xl">
              のうKNOW®
            </Text>
            <Text size="large">オンライン認知機能検査</Text>
          </LayoutBox>
        </LayoutBox>
      }
      onClick={handleChangeExamination}
      disabled={!listResult || listResult.length === 0}
    />
  );
};
export default TopNouknowCardWithdrawal;
