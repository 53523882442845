import React, { useCallback, useEffect } from "react";
import SpButton from "../components/Button/SpButton";
import CheckboxGroup, {
  CheckboxGroupProps,
} from "../components/Checkbox/CheckboxGroup";
import Divider from "../components/Divider/Divider";
import SpFormSet from "../components/FormSet/SpFormSet";
import HeaderUser from "../components/Header/HeaderUser";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import BulletList from "../components/List/BulletList";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Title from "../components/Title/Title";
import PatientFooter from "../features/PatientFooter";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import { BULLET_LIST_ITEMS } from "../constants/common";
import useBookingApply from "../hooks/common/useBookingApply";
import { useCheckErrorThrowError } from "../utils/checkError";

const CHECKBOX_ITEMS = [
  { label: "当てはまらない", checked: false, value: "1" },
];
const CHECKBOX_ITEMS2 = [
  { label: "冠動脈ステントが入っている方", checked: false, value: "1" },
  { label: "動脈瘤クリップが入っている方", checked: false, value: "2" },
  { label: "人工関節が入っている方", checked: false, value: "3" },
  {
    label: (
      <LayoutBox direction="column">
        <Text size="xl">上記以外の体内金属異物が入っている方</Text>
        <Text size="base" color="neutralUserLight">
          手術の時期や場所によって、検査を受けられないことがあります。
        </Text>
      </LayoutBox>
    ),
    checked: false,
    value: "4",
  },
  {
    label: (
      <LayoutBox direction="column">
        <Text size="xl">刺青・タトゥが入っている方</Text>
        <Text size="base" color="neutralUserLight">
          熱感、退色等の可能性があります。
        </Text>
      </LayoutBox>
    ),
    checked: false,
    value: "5",
  },
  {
    label: "現在妊娠中、または妊娠の可能性のある方",
    checked: false,
    value: "6",
  },
];

const KEY_CHECKBOX_ALLOW = "checkbox-allow";
const KEY_CHECKBOX_NONE = "checkbox-none";

const USC019 = () => {
  const navigate = useNavigateCustom();
  const [checkedItems, setCheckedItems] =
    React.useState<NonNullable<CheckboxGroupProps["items"]>>(CHECKBOX_ITEMS);
  const [checkedItems2, setCheckedItems2] =
    React.useState<NonNullable<CheckboxGroupProps["items"]>>(CHECKBOX_ITEMS2);
  const [checkboxAllow, setCheckboxAllow] = React.useState<
    NonNullable<CheckboxGroupProps["items"]>[number]
  >({
    label:
      "上記に当てはまる項目がある場合、医療機関の判断により受検できない可能性があることを承諾する",
    checked: false,
    disabled: true,
    className: "util-mt-16",
    value: KEY_CHECKBOX_ALLOW,
  });

  const [checkboxNone, setCheckboxNone] = React.useState<
    NonNullable<CheckboxGroupProps["items"]>[number]
  >({
    label: "上記に当てはまる項目はない",
    checked: false,
    className: "util-mt-16",
    value: KEY_CHECKBOX_NONE,
  });

  const mergedItems = [...checkedItems2, checkboxAllow, checkboxNone];

  const [prefecture, setPrefecture] = React.useState("");
  const [selectedDateTimes, setSelectedDateTimes] = React.useState<
    Array<string>
  >([]);
  const [selectHospitalId, setSelectHospitalId] = React.useState("");
  const [selectedDates, setSelectedDates] = React.useState<Array<Date>>([]);
  const [selectedTimes, setSelectedTimes] = React.useState<
    Array<string | undefined>
  >([]);
  const [selectHospitalName, setSelectHospitalName] = React.useState("");

  const isActiveCheckbox = checkedItems2.some((item) => item.checked);
  const allowNext = checkboxAllow.checked || checkboxNone.checked;

  const { actionError: bookingApplyError, applyBooking } = useBookingApply();

  useCheckErrorThrowError([bookingApplyError]);

  // セッションストレージの値を復元
  useEffect(() => {
    const savedFormDataString = sessionStorage.getItem("mriBookingInput");
    if (savedFormDataString) {
      const savedFormData = JSON.parse(
        savedFormDataString,
      ) as MriPetSavedFormData;

      if (savedFormData.selectedDates && savedFormData.selectedTimes) {
        const combinedArray = savedFormData.selectedDates.map((date, index) => {
          const dateObj = new Date(date);
          const formattedDate = dateObj
            .toLocaleDateString("ja-JP", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/\//g, "/");

          return `${formattedDate} ${savedFormData.selectedTimes[index]}`;
        });
        setSelectedDateTimes(combinedArray);
      }

      // savedFormData.selectedDates から Date オブジェクトの配列を生成
      const dates = savedFormData.selectedDates.map(
        (dateString) => new Date(dateString),
      );

      setPrefecture(savedFormData.prefecture);
      setSelectHospitalName(savedFormData.selectHospitalName);
      setSelectHospitalId(savedFormData.selectHospitalId);
      setSelectedDates(dates);
      setSelectedTimes(savedFormData.selectedTimes);
    }
  }, []);

  useEffect(() => {
    const nextCheckboxAllow = {
      ...checkboxAllow,
      disabled: !isActiveCheckbox,
    };
    if (!isActiveCheckbox) {
      nextCheckboxAllow.checked = false;
    }

    setCheckboxAllow(nextCheckboxAllow);

    const nextCheckboxNone = {
      ...checkboxNone,
      disabled: isActiveCheckbox,
    };

    if (isActiveCheckbox) nextCheckboxNone.checked = false;
    setCheckboxNone(nextCheckboxNone);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveCheckbox]);

  const handleCheckboxChange = (value: string) => {
    const updatedCheckboxItems = checkedItems.map((item) =>
      item.value === value ? { ...item, checked: !item.checked } : item,
    );
    setCheckedItems(updatedCheckboxItems);
  };

  const handleCheckbox2Change = (value: string) => {
    if (value === KEY_CHECKBOX_ALLOW) {
      setCheckboxAllow({ ...checkboxAllow, checked: !checkboxAllow.checked });

      return;
    }
    if (value === KEY_CHECKBOX_NONE) {
      setCheckboxNone({ ...checkboxNone, checked: !checkboxNone.checked });

      return;
    }

    const updatedCheckboxItems = checkedItems2.map((item) =>
      item.value === value ? { ...item, checked: !item.checked } : item,
    );
    setCheckedItems2(updatedCheckboxItems);
  };

  // 戻るボタン
  const handleClickBack = useCallback(
    () => navigate(PATIENT.USC007),
    [navigate],
  );

  // 申し込みボタン押下
  const handleApply = async () => {
    const mriContraindications = checkedItems[0].checked;
    const precautions = checkedItems2
      .filter((item) => item.checked)
      .map((item) => Number(item.value));

    await applyBooking(
      "mri",
      selectHospitalId,
      selectedDates,
      selectedTimes,
      mriContraindications,
      precautions,
    );
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          selectedTab={SELECTED_TAB_NUM.examination}
          minWidth="1076px"
        />
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header="確認"
          borderBottom="primary"
          onClickBack={handleClickBack}
        />
        <Sheet padding="40px 0">
          <LayoutBox gap="3x" direction="column" fullWidth>
            <LayoutBox
              direction="column"
              justify="center"
              align="center"
              gap="2x"
              fullWidth
              minWidth="1064px"
            >
              <Sheet
                type="card"
                padding="16px 32px 32px 32px"
                maxWidth="1064px"
                width="100%"
              >
                <LayoutBox direction="column" gap="3x" fullWidth>
                  <LayoutBox direction="column" fullWidth>
                    <Text size="2xl" bold>
                      医療機関の予約
                    </Text>
                    <Divider borderWidth="3px" margin="0" />
                  </LayoutBox>
                  <LayoutBox
                    direction="column"
                    gap="3x"
                    fullWidth
                    className="util-mt-8"
                  >
                    <LayoutBox direction="column" gap="2x" fullWidth>
                      <SpFormSet label="都道府県" labelGap="small" vertical>
                        <LayoutBox direction="column" gap="1x" fullWidth>
                          <Text size="xl">{prefecture}</Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                      <SpFormSet label="提携病院" labelGap="small" vertical>
                        <LayoutBox direction="column" gap="1x" fullWidth>
                          <Text size="xl">{selectHospitalName}</Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                    </LayoutBox>
                    <LayoutBox direction="column" gap="3x" fullWidth>
                      {selectedDateTimes.map((date, index) => (
                        <LayoutBox
                          key={index}
                          direction="column"
                          gap="2x"
                          fullWidth
                        >
                          <Text size="xl" bold color="neutralUserBlack">
                            第{index + 1}候補
                          </Text>
                          <LayoutBox direction="column" gap="1x" fullWidth>
                            <Text size="xl" color="neutralUserBlack">
                              {date}
                            </Text>
                            <Divider darkColor margin="0" />
                          </LayoutBox>
                        </LayoutBox>
                      ))}
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
              <Sheet
                type="card"
                padding="16px 16px 32px 16px"
                maxWidth="1064px"
                width="100%"
              >
                <LayoutBox direction="column" fullWidth gap="3x">
                  <Title
                    header="MRI検査の禁忌事項について"
                    borderBottom="primaryLight"
                    withButton={false}
                    type="h2"
                  />
                  <LayoutBox direction="column" gap="2x" fullWidth>
                    <LayoutBox direction="column">
                      <Text size="xl" color="error">
                        以下に当てはまる方はMRI検査を受検できません。
                      </Text>
                      <Text size="xl" color="error">
                        該当する項目がないことをご確認ください。
                      </Text>
                    </LayoutBox>
                    <Sheet type="border-red" borderRadius="8px" padding="16px">
                      <BulletList
                        title="体内に下記の医療機器がある方"
                        items={BULLET_LIST_ITEMS}
                        color="red"
                      />
                    </Sheet>
                  </LayoutBox>
                  <CheckboxGroup
                    items={checkedItems}
                    withContainer
                    onChecked={handleCheckboxChange}
                  />
                </LayoutBox>
              </Sheet>
              <Sheet
                type="card"
                padding="16px 16px 32px 16px"
                maxWidth="1064px"
                width="100%"
              >
                <LayoutBox direction="column" fullWidth gap="3x">
                  <Title
                    header="MRI検査の注意事項について"
                    borderBottom="primaryLight"
                    withButton={false}
                    type="h2"
                  />
                  <LayoutBox direction="column">
                    <Text size="xl" color="error">
                      以下の項目に当てはまる方はチェックをしてください。
                    </Text>
                    <Text size="xl" color="error">
                      当てはまる項目がある場合、各医療機関の判断によりMRI検査を受検できない可能性があることをご承諾の上、ご予約へお進みください。
                    </Text>
                    <Text size="xl">
                      ご不明な点があれば事前に受検予定の医療機関にご確認ください。
                    </Text>
                  </LayoutBox>
                  <CheckboxGroup
                    gap="8px"
                    items={mergedItems}
                    withContainer
                    onChecked={handleCheckbox2Change}
                  />
                </LayoutBox>
              </Sheet>
            </LayoutBox>
            <LayoutBox
              justify="between"
              gap="2x"
              className="util-sp-full-width util-mx-auto"
              wrapReverse
              width="1064px"
            >
              <SpButton
                type="secondary"
                width="400px"
                size="medium"
                withArrowLeft
                mobileFullWidth
                onClick={handleClickBack}
              >
                戻る
              </SpButton>
              <SpButton
                type="primary"
                width="400px"
                size="medium"
                withArrowRight
                mobileFullWidth
                disabled={!(allowNext && checkedItems[0]?.checked)}
                onClick={() => {
                  void handleApply();
                }}
              >
                申し込む
              </SpButton>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC019;
