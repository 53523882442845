import Card from "../components/Card/Card";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { useCheckErrorThrowError } from "../utils/checkError";
import useUsc021Actions from "../hooks/pages/USC021/useUsc021DBActions";

const PetCardWithdrawal = () => {
  const navigate = useNavigateWithUrl();
  const { error, petListResult } = useUsc021Actions({ orderYear: null });

  // エラー処理
  useCheckErrorThrowError([error]);

  const handleChangeExamination = () => {
    const queryParams = new URLSearchParams();
    queryParams.set("category", "4");

    navigate(`${PATIENT.USC026}?${queryParams.toString()}`);
  };

  return (
    <Card
      header={
        <>
          <LayoutBox
            gap="1/2x"
            justify="start"
            align="start"
            flexShrink={0}
            direction="row"
            minHeight="65px"
          >
            <LayoutBox
              gap="1/2x"
              justify="center"
              align="start"
              flexShrink={0}
              direction="column"
            >
              <Text bold size="xl">
                FDG-PET検査(別途料金)
              </Text>
            </LayoutBox>
          </LayoutBox>
        </>
      }
      onClick={handleChangeExamination}
      disabled={petListResult.length === 0}
    />
  );
};
export default PetCardWithdrawal;
