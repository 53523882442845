export enum ResponseStatusCode {
  OK = 200,
  NG = 400,
  ACCEPT = 202,
  CREATED = 201,
}

export enum ErrorCode {
  NORMAL = 0,
  BAD_REQUEST = 1,
  NOT_UPDATED = 2,
}

export enum Collection {
  ADMINS = "admins",
  CAMPAIGNS = "campaigns",
  HOSPITAL_USERS = "hospital_users",
  HOSPITALS = "hospitals",
  ITEM_TAGS = "item_tags",
  ITEMS = "items",
  NOTIFICATIONS = "notifications",
  ORDERS = "orders",
  PATIENT_BRAINSUITES = "patient_brainsuites",
  PATIENT_INTERVIEWS = "patient_interviews",
  PATIENT_NOUKNOWS = "patient_nouknows",
  PATIENTS = "patients",
  SALES = "sales",
  SKUS = "skus",
  SETTINGS = "settings",
  SEQUENCE = "sequence",
  PATIENT_MODALITY_BOOKS = "patient_modality_books",
  OPTION_CONFIGS = "option_configs",
  OPTION_CONTENTS = "option_contents",
  USERS = "users",
  YOSES = "yoses",
  SUBSCRIPTION_PLANS = "subscription_plans",
  PATIENT_MESSAGE_RELATIONSHIPS = "patient_message_relationships",
  PATIENT_ONLINE_TESTS = "patient_online_tests",
  PATIENT_INVITATIONS = "patient_invitations",
  CHATS = "chats",
  INVITATION_TICKETS = "invitation_tickets",
  PATIENT_SUBSCRIPTIONS = "patient_subscriptions",
  PATIENT_BRAIN_CHECKS = "patient_brain_checks",
  CAMPAIGN_COUPONS = "campaign_coupons",
  SHIPPINGS = "shippings",
}

export const INIT_PAGE = 1;
export const INIT_PER_PAGE = 10;

export const BRAIN_CHECKS_PHONE_NUMBER = "03-5324-2045";
export const CONSULTATION_PHONE_NUMBER = "03-5324-2045";

export enum PatientNouKnowStatusID {
  PRETEST = 0,
  COMPLETED = 1,
  UPLOADED = 2,
  CONFIRMED = 4,
  COMMENTED = 5,
  CANCELLATION = 6,
}

export enum PatientBrainCheckStatusID {
  NOT_TESTED,
  COMPLETED,
  REPORTED,
}

export enum PatientInterviewStatusID {
  NEW_RESERVATION,
  CONFIRMED_RESERVATION,
  SETTLED,
}

export enum PatientModalityBookStatusID {
  EXCLUDE,
  IN_PROGRESS,
  RESERVED,
  RESERVATION_CONFIRMED,
  IMAGE_NOT_LINKED,
  COMPLETED_UNDER_AI_DIAGNOSE,
  COMPLETED_AI_DIAGNOSE,
  COMPLETED_DOC_DIAGNOSE,
  USER_SUPPORTED,
  WITHDRAWAL,
  NG,
}

export enum PatientModalityBookMriProblemID {
  EXCLUDE,
  PROBLEM,
  NO_PROBLEM,
}

export enum NotificationStatusID {
  UNPUBLISHED,
  NOW_OPEN,
  DELETED,
}

export enum CampaignsStatusID {
  UNPUBLISHED,
  NOW_OPEN,
}

export enum CampaignCouponsStatusID {
  APPLY = 1,
  DELETED = 2,
}

export enum EmbeddedPageID {
  MY_PAGE,
  YOSE_LIST,
  YOSE_DETAILS,
  EC_LIST,
  EC_DETAILS,
}

export enum EmbeddedLocationID {
  TOP,
  FOOTER,
}

export enum HospitalRoleID {
  GENERAL,
  ADMIN,
}

export enum HospitalStatusID {
  IN_USE,
  WITHDRAWAL,
}

export enum OrderStatusID {
  CART_KEPT,
  ORDER_PENDING_PAYMENT_OR_AUTH,
  PAYMENT_COMPLETED,
  SHIPPED,
  DELIVERY_COMPLETED,
  CANCELLED = 9,
}
export enum DeliveryMethodID {
  NORMAL = 1,
  SPECIFIED = 2,
}
export enum ItemsStatusID {
  HIDDEN,
  VISIBLE,
  DELETED,
}
export enum ItemsSkusStatusID {
  HIDDEN,
  VISIBLE,
  DELETED,
}
export enum OptionConfigsStatusID {
  HIDDEN,
  VISIBLE,
}

export enum AdminsStatusID {
  NON_ACTIVE,
  ACTIVE,
  DELETED,
}

export enum AdminsRoleID {
  ADMIN = 1,
  CMC = 2,
  PUBLIC_USER = 3,
}
export enum ModalityID {
  MRI,
  PET,
}
export enum HospitalReservationStatusID {
  NEW,
  CONFIRM,
  NG,
  APPLICATION_FOR_WITHDRAWAL,
  WITHDRAWAL,
}
export enum HospitalInspectionStatusID {
  NOT_INSPECTED,
  INSPECTED,
}

export enum PetStatusID {
  NON_TARGET = 0,
  GUIDING = 1,
  RESERVED = 2,
  RESERVATION_CONFIRMED = 3,
  EXAMINED = 4,
  DIAGNOSED = 5,
  USER_REPORTED = 6,
  WITHDRAWN_AFTER_CONFIRMATION = 7,
  NG_BEFORE_CONFIRMATION = 8,
  NO_EXAMINATION = 9,
}

export enum MriStatusID {
  NON_TARGET = 0,
  GUIDING = 1,
  RESERVED = 2,
  RESERVATION_CONFIRMED = 3,
  DONE_IMAGE_NOT_LINKED = 4,
  DONE_AI_DIAGNOSING = 5,
  DONE_AI_DIAGNOSED = 6,
  DONE_DOCTOR_DIAGNOSED = 7,
  USER_REPORTED = 8,
  WITHDRAWN_AFTER_CONFIRMATION = 9,
  NG_BEFORE_CONFIRMATION = 10,
  NO_EXAMINATION = 11,
}

export enum UserTypeID {
  ADMIN = 1,
  PATIENT = 2,
  HOSPITAL = 3,
}

export enum UserStatusID {
  UNREGISTERED,
  ACTIVE,
  INVITATION,
  CANCELLATION,
  SUSPENDED,
  DELETED,
}
export enum PatientTypeID {
  MEMBER = 1,
  INVITATION = 2,
  PRESENT = 3,
  ORG = 4,
}

export enum PlanSpanID {
  MONTH = 1,
  YEAR = 2,
}

export enum PatientMessageRelationshipStatusID {
  UNREAD,
  ALREADY_READ,
  ARCHIVE,
}

export enum YOSE_STATUS {
  preDelivery,
  newPeriod,
  publicOpen,
  publicClose,
  suspend,
}

export enum ONLINE_TEST_STATUS {
  NOT_IMPLEMENTED,
  IMPLEMENTED,
  RESULT_ALREADY_OBTAINED,
}

export const YOSE_TAB_MENUS = {
  public: "1",
  close: "2",
} as const;

export enum SALES_STATUS {
  creditAcquisition,
  salesCompleted,
  creditCancellation,
  creditFailure,
  salesCancellation,
  salesFailure,
}

export enum SALES_CATEGORY {
  subscription,
  interview,
  ec,
  pet,
  mri,
}

export enum PATIENT_INVITATIONS_STATUS {
  notRegistered,
  currentlyApplying,
  registered,
  denial,
  withdrawal,
  cancel,
  paymentError,
}

export const EmbeddedStr = {
  PAGE: {
    [EmbeddedPageID.MY_PAGE]: "マイページ",
    [EmbeddedPageID.YOSE_LIST]: "寄席一覧",
    [EmbeddedPageID.YOSE_DETAILS]: "寄席詳細",
    [EmbeddedPageID.EC_LIST]: "EC一覧",
    [EmbeddedPageID.EC_DETAILS]: "EC詳細",
  },
  LOCATION: {
    [EmbeddedLocationID.TOP]: "トップ",
    [EmbeddedLocationID.FOOTER]: "フッター",
  },
};

export const MONTH_LIST = [
  { value: "01", label: "1" },
  { value: "02", label: "2" },
  { value: "03", label: "3" },
  { value: "04", label: "4" },
  { value: "05", label: "5" },
  { value: "06", label: "6" },
  { value: "07", label: "7" },
  { value: "08", label: "8" },
  { value: "09", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

// 日本時間
export const JST = "+09:00";
export const JST_OFFSET_HOURS = 9;

// 消費税10%
export const TAX_10_PER = {
  label: "消費税10%対象",
  rate: "10%",
  calcTaxNum: 1.1,
};

// 消費税8%
export const TAX_8_PER = {
  label: "消費税8%対象",
  rate: "8%",
  calcTaxNum: 1.08,
};

// 画像アップロード最大個数
export const IMG_DEFAULT_MAX_COUNT = 4;

export const RECOMMEND_TERMS = [
  {
    value: "99",
    label: "すべて",
  },
  {
    value: "1",
    label: "A",
  },
  {
    value: "2",
    label: "B",
  },
  {
    value: "3",
    label: "C",
  },
  {
    value: "4",
    label: "D",
  },
  {
    value: "5",
    label: "E",
  },
  {
    value: "0",
    label: "設定しない",
  },
];
export const RECOMMEND_SCOPES = [
  {
    value: "1",
    label: "以上",
  },
  {
    value: "2",
    label: "以下",
  },
  {
    value: "3",
    label: "-",
  },
];

export const TAX_RATE = [
  {
    rate: 8,
    label: "8%",
  },
  {
    rate: 10,
    label: "10%",
  },
];

// ユーザ評価初期値
export const INIT_EVALUATION = "3";

// ユーザ評価選択肢
export const USER_EVALUATION = [
  {
    column: 5,
    items: [
      {
        label: "A",
        value: "1",
      },
      {
        label: "B",
        value: "2",
      },
      {
        label: "C",
        value: "3",
      },
      {
        label: "D",
        value: "4",
      },
      {
        label: "E",
        value: "5",
      },
    ],
  },
];

export const REGISTER_STEP_ITEMS = [
  { id: "1", label: "お客様情報の入力" },
  { id: "2", label: "お支払いコースの選択" },
  { id: "3", label: "登録完了" },
];

export const RADIO_GENDER_ITEMS = [
  {
    label: "男性",
    value: "1",
  },
  {
    label: "女性",
    value: "2",
  },
];

export const RADIO_NOTICE_ITEMS = [
  {
    label: "希望する",
    value: "1",
  },
  {
    label: "希望しない",
    value: "2",
  },
];

export const RADIO_WITH_CODE_ITEMS = [
  {
    label: "あり",
    value: "1",
  },
  {
    label: "なし",
    value: "2",
  },
];

export const BULLET_LIST_ITEMS = [
  {
    id: "1",
    value: "心臓ペースメーカー",
  },
  {
    id: "2",
    value: "植込型除細動器",
  },
  {
    id: "3",
    value: "体内刺激電極",
  },
  {
    id: "4",
    value: "人工内耳",
  },
  {
    id: "5",
    value: "心臓ペーシングワイヤー",
  },
  {
    id: "6",
    value: "磁性体脳動脈瘤クリップ",
  },
  {
    id: "7",
    value: "マグネット式インプラント",
  },
  {
    id: "8",
    value: "植え込み型AED",
  },
  {
    id: "9",
    value: "機械式人工弁",
  },
  {
    id: "10",
    value: "磁性体義眼",
  },
  {
    id: "11",
    value: "乳房再建用の皮膚拡張器",
    helperText: "※MRI検査可能なものは除く",
  },
  {
    id: "12",
    value: "金の糸（金糸）",
  },
  {
    id: "13",
    value: "持続血糖測定器（CGM、FGM）",
    helperText: "※MRI検査可能なものは除く",
  },
];

export const REGISTER_MODALITY_CHECK_ITEMS = [
  { label: "当てはまらない", checked: false },
];

// 都道府県の選択肢
export const PREFECTURE_OPTIONS = [
  {
    label: "北海道",
    value: "北海道",
  },
  {
    label: "青森県",
    value: "青森県",
  },
  {
    label: "岩手県",
    value: "岩手県",
  },
  {
    label: "宮城県",
    value: "宮城県",
  },
  {
    label: "秋田県",
    value: "秋田県",
  },
  {
    label: "山形県",
    value: "山形県",
  },
  {
    label: "福島県",
    value: "福島県",
  },
  {
    label: "茨城県",
    value: "茨城県",
  },
  {
    label: "栃木県",
    value: "栃木県",
  },
  {
    label: "群馬県",
    value: "群馬県",
  },
  {
    label: "埼玉県",
    value: "埼玉県",
  },
  {
    label: "千葉県",
    value: "千葉県",
  },
  {
    label: "東京都",
    value: "東京都",
  },
  {
    label: "神奈川県",
    value: "神奈川県",
  },
  {
    label: "新潟県",
    value: "新潟県",
  },
  {
    label: "富山県",
    value: "富山県",
  },
  {
    label: "石川県",
    value: "石川県",
  },
  {
    label: "福井県",
    value: "福井県",
  },
  {
    label: "山梨県",
    value: "山梨県",
  },
  {
    label: "長野県",
    value: "長野県",
  },
  {
    label: "岐阜県",
    value: "岐阜県",
  },
  {
    label: "静岡県",
    value: "静岡県",
  },
  {
    label: "愛知県",
    value: "愛知県",
  },
  {
    label: "三重県",
    value: "三重県",
  },
  {
    label: "滋賀県",
    value: "滋賀県",
  },
  {
    label: "京都府",
    value: "京都府",
  },
  {
    label: "大阪府",
    value: "大阪府",
  },
  {
    label: "兵庫県",
    value: "兵庫県",
  },
  {
    label: "奈良県",
    value: "奈良県",
  },
  {
    label: "和歌山県",
    value: "和歌山県",
  },
  {
    label: "鳥取県",
    value: "鳥取県",
  },
  {
    label: "島根県",
    value: "島根県",
  },
  {
    label: "岡山県",
    value: "岡山県",
  },
  {
    label: "広島県",
    value: "広島県",
  },
  {
    label: "山口県",
    value: "山口県",
  },
  {
    label: "徳島県",
    value: "徳島県",
  },
  {
    label: "香川県",
    value: "香川県",
  },
  {
    label: "愛媛県",
    value: "愛媛県",
  },
  {
    label: "高知県",
    value: "高知県",
  },
  {
    label: "福岡県",
    value: "福岡県",
  },
  {
    label: "佐賀県",
    value: "佐賀県",
  },
  {
    label: "長崎県",
    value: "長崎県",
  },
  {
    label: "熊本県",
    value: "熊本県",
  },
  {
    label: "大分県",
    value: "大分県",
  },
  {
    label: "宮崎県",
    value: "宮崎県",
  },
  {
    label: "鹿児島県",
    value: "鹿児島県",
  },
  {
    label: "沖縄県",
    value: "沖縄県",
  },
];

export const DROPDOWN_MONTH_OPTIONS: { label: string; value: string }[] =
  Array.from({ length: 12 }, (_, i) => {
    const label = `${i + 1}`;

    return {
      label,
      value: label,
    };
  });

export const DROPDOWN_YEAR_OPTIONS: { label: string; value: string }[] =
  Array.from({ length: 2023 - 1920 + 1 }, (_, i) => {
    const year = (1920 + i).toString();

    return {
      label: year,
      value: year,
    };
  });

export const USER_PLAN_CATEGORY = {
  standardPlan: 2,
} as const;

export const PAYMENT_PERIOD = {
  monthlyPayment: 1,
  annualPayment: 2,
} as const;

export const FORMAT_STYLE = {
  "YYYY/MM/DD": 0,
  YYYYMMDD: 1,
  "YYYY/MM/DD HH:MM": 3,
  "HH:MM": 4,
  "YYYY-MM-DD": 5,
  JPN_YMD: 6,
} as const;

// USERテーブルにおけるstatus
export const USER_STATUS = {
  unregistered: 0, // ユーザー情報未登録
  activeUser: 1, // アクティブ
  authenticatingEmail: 2, //  メール認証中
  cancelAndDelete: 3, // 解約・削除
  unsubscribe: 4, //  停止
} as const;

export const CANCELABLE_HOUR = 1;

export const DELIVERY_AVAILABLE_DATE = 7;
// キャンペーンバナーの表示場所
export const CAMPAIGNS_BANNER_POSITION = {
  top: 0,
  bottom: 1,
} as const;

// テスト・結果トップ画面のタブ
export const USC_TAB_ITEMS = [
  {
    id: "1",
    label: "のうKNOW",
  },
  {
    id: "2",
    label: "MRI検査",
  },
  {
    id: "3",
    label: "PET検査",
  },
  {
    id: "4",
    label: "健康チェック",
  },
];

// テスト・結果トップ画面のタブ
export const USC_021_TAB_ITEMS = [
  {
    id: "1",
    label: "検査",
  },
  {
    id: "2",
    label: "結果",
  },
];

export enum INTERVIEW_STATUS {
  newBooking, // 新規予約
  interviewFixedAfterPayment, // 面談確定 決済済み
  interviewDone, // 面談済み
  interviewFixedBeforePayment, // 面談確定 決済前
  canceled = 9, // キャンセル済み

  // 以降フロントで追加分
  beforeFreeApplication = 999, // 無料申し込み前
  beforePaidApplication = 1000, // 有料申し込み前
}

export const INIT_SEARCH_CRITERIA = {
  USA031: {
    id: "",
    orderYear: "",
  },
  USC021: {
    id: "",
    orderYear: "",
    category: "",
  },
};

export const NOUKNOW_SCORE_TEXT = {
  A: "正常な状態です",
  B: "ボーダーライン",
  C: "維持向上のための活動を取り⼊れましょう",
};

export const PAYMENT_SPAN = {
  month: 1,
  year: 2,
} as const;

export const mobileView = 768;

export const SERVICE_CONTENT_INSPECTION_ITEMS = [
  {
    id: "1",
    value: "オンライン認知機能検査「のうKNOW®」（3か月ごと）",
  },
  {
    id: "2",
    value:
      "電話認知機能検査「あたまの健康チェック®」（年1回/入会後6か月目以降受検可）",
  },
  {
    id: "3",
    value:
      "MRI検査＋AI解析「MRI検査＋BrainSuite®」（年1回/入会後10か月目以降受検可）",
  },
];

export const SERVICE_CONTENT_CONSULTATION_ITEMS = [
  {
    id: "1",
    value: "24時間電話相談「認知症相談ダイヤル24」",
  },
  {
    id: "2",
    value: "チャット相談",
  },
  {
    id: "3",
    value: "オンライン面談(医師)　※MRI検査付帯",
  },
  {
    id: "4",
    value: "医療機関＆専門医紹介",
  },
];

export const SERVICE_CONTENT_DETAILED_INSPECTION_ITEMS = [
  {
    id: "1",
    value: "FDG‐PET検査　※別途料金",
  },
];
