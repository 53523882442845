import HeaderUser from "../components/Header/HeaderUser";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Title from "../components/Title/Title";
import Icon from "../components/Icon/Icon";
import SpButton from "../components/Button/SpButton";
import Divider from "../components/Divider/Divider";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import PatientFooter from "../features/PatientFooter";
import useUsc014DBActions from "../hooks/pages/USC014/useUsc014DBActions";
import { PATIENT } from "../constants/pagePaths";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { endOfPeriodForMri, getUserYearSinceActivation } from "../utils/utils";
import { useRealmApp } from "../contexts/RealmApp";
import usePatientMriFetch from "../hooks/common/usePatientMriFetch";
import usePatientNouknowsFetch from "../hooks/common/usePatientNouknowsFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useUsc007Fetch from "../hooks/pages/USC007/useUsc007Fetch";
import { PatientNouKnowStatusID } from "../constants/common";

const USC014 = () => {
  const realmAppContext = useRealmApp();
  const navigate = useNavigateCustom();

  // 何年目のユーザか
  const userYearSinceActivation = getUserYearSinceActivation(
    realmAppContext.currentUser,
  );

  const { isOnlineTest } = useUsc007Fetch();
  const { patientMri, error } = usePatientMriFetch(userYearSinceActivation);
  const { patientNouknows, error: nouknowError } = usePatientNouknowsFetch();

  // エラー処理
  useCheckErrorThrowError([nouknowError, error]);

  // MRI受検可能か
  const isMriPossible = () => {
    // 当年度のMRIのデータが存在する場合、受検不可
    if (patientMri.length > 0) {
      return false;
    }

    // オンラインテスト未受検の場合、受検不可
    if (!isOnlineTest) {
      return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // attempts を取得
    const attempts = parseInt(
      process.env.REACT_APP_NOUKNOW_ATTEMPTS || "4",
      10,
    );

    // のうKnow4回目が実施されているか
    // POCの場合のうKnow1回目が実施されているかどうか
    const isValidItem = (item: {
      status: number;
      count_of_nouknow: number;
    }) => {
      const countCondition = attempts === 1 ? 1 : attempts === 4 ? 4 : 0;

      return (
        ![
          PatientNouKnowStatusID.PRETEST,
          PatientNouKnowStatusID.CANCELLATION,
        ].includes(item.status) &&
        item.count_of_nouknow >= 1 &&
        item.count_of_nouknow <= countCondition
      );
    };

    const validNouknows = patientNouknows.filter(isValidItem);
    if (validNouknows.length === 0) {
      return false;
    }

    const activationDateObject = realmAppContext.currentUser?.customData
      .activation_date as CustomDateType;
    const activationDate = new Date(
      Number(activationDateObject.$date.$numberLong),
    );
    activationDate.setHours(0, 0, 0, 0);

    // アクティベート日付にPET受検可能期間を加算
    activationDate.setDate(
      activationDate.getDate() + endOfPeriodForMri(realmAppContext.currentUser),
    );

    if (today <= activationDate) {
      return true;
    }

    return false;
  };

  // patient_nouknows更新
  useUsc014DBActions();

  // トップに戻る
  const handleGoTop = () => {
    navigate(PATIENT.USB001);
  };
  const handleGoMri = () => {
    navigate(PATIENT.USC024);
  };

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          selectedTab={SELECTED_TAB_NUM.examination}
          minWidth="1076px"
        />
        <Title
          minWidth="1076px"
          padding="16px 108px 24px 108px"
          background="default"
          header="のうKNOW®"
          borderBottom="primary"
          withButton={false}
        />
        <LayoutBox justify="center">
          <Sheet padding="24px" maxWidth="1112px">
            <Sheet type="card">
              <LayoutBox direction="column" fullWidth align="center" gap="3x">
                <LayoutBox direction="column" fullWidth align="center" gap="2x">
                  <Icon color="#388e3c" icon="check_circle" size="xl" />
                  <Text bold size="2xl">
                    テストが完了しました
                  </Text>
                  <Text textAlign="center" size="xl">
                    数日後に結果画面からテスト結果とレポートを確認できるようになります。
                  </Text>
                </LayoutBox>
                <Divider orange={false} darkColor margin="0" />
                {isMriPossible() && (
                  <SpButton
                    width="311px"
                    size="medium"
                    mobileFullWidth
                    iconSize="large"
                    withArrowLeft
                    onClick={handleGoMri}
                  >
                    MRI検査＋AI解析へ
                  </SpButton>
                )}

                <SpButton
                  type="secondary"
                  width="311px"
                  size="medium"
                  mobileFullWidth
                  iconSize="large"
                  withArrowLeft
                  onClick={handleGoTop}
                >
                  トップに戻る
                </SpButton>
              </LayoutBox>
            </Sheet>
          </Sheet>
        </LayoutBox>
        <div className="util-mb-40" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC014;
